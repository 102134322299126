import React from "react"
import { BlogPost, StaticContext } from "../domain/interfaces"
import { Helmet } from "react-helmet"
import HeaderMenu from "../components/Header"
import BlogCardBanner from "../components/BlogCardBanner"
import Footer from "../components/Footer"


const BlogPage: React.FunctionComponent<{pageContext: {staticContext: StaticContext, blogPost: BlogPost}}> = ({pageContext}) => {

    const {staticContext, blogPost} = pageContext;

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Chamantinis | {blogPost.title} </title>
            </Helmet>
            <HeaderMenu contact={staticContext.contact} menu={staticContext.categories}/>

            <div className="w-full max-w-screen-md mt-10 mx-auto blog-article">

                <div className="w-full">
                    <div className="mx-auto w-full text-center text-brand-primary text-xs"> {blogPost.publishDateFormated} </div>
                    <h1 className=""> {blogPost.title} </h1>

                    <div className="w-full">   
                        <img className="object-contain rounded-md mt-5 w-full" src={blogPost.imgUrl} />
                    </div>

                    <div className="px-10" dangerouslySetInnerHTML={{__html: blogPost.articleHTML}}></div>
                    
                </div>

            </div>

            <BlogCardBanner banner={{title: "Nos derniers articles", cards: staticContext.blogPosts}} />
            <Footer contact={staticContext.contact} group1={staticContext.footer.group1} group2={staticContext.footer.group2} group3={staticContext.footer.group3} />
        </div>
    )

}

export default BlogPage